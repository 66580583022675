<template>


  <h3>{{ $t("reorder.pleaseSelect") }}</h3>
  <div class="selectBoxes">

    <div class="box" @click="goPrivate" >
      <h3>{{ $t("usage.private") }}</h3>
    </div>
    <div class="box" @click="goCommercial" >
      <h3>{{ $t("usage.commercial") }}</h3>
    </div>
  </div>

    <button class="btn" @click="$router.push('/')"  >{{ $t("app.back") }}</button>



</template>
<script>
export default {
  name: "Digital",
  methods: {
    goPrivate() {
      this.$store.state.order.countCoupons = 1;
      this.$store.state.order.usage = "p"
      this.$router.push("/address");
    },
    goCommercial() {
      this.$store.state.order.countCoupons = 1;
      this.$store.state.order.usage = "c"
      this.$router.push("/address");
    }
  },
  // set progress to 10
  mounted() {
    this.$store.state.progress = 20
  },
  created(){
    this.$store.state.order.address = {c_company: "",
      c_firstname: "",
      c_lastname: "",
      c_street:"",
      c_number: "",
      c_zipcode:"",
      c_email: ""};
  }
}

</script>
<style scoped>
</style>
