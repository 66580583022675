<template>
   <footer>
     <ul>
       <li>
          <a href="https://www.entsorgungsdaten.de/impressum" target="_blank">{{ $t("footer.imprint") }}</a>
       </li>
       <li>
          <a href="https://www.entsorgungsdaten.de/datenschutz" target="_blank">{{ $t("footer.privacy") }}</a>
       </li>
     </ul>
     <span class="copyright">Ein Service der <a href="https://www.meindl-entsorgung.de" target="_blank">Meindl Entsorgungsservice GmbH</a></span>
   </footer>
</template>
<script>
export default {
  name: "footerView",
  props: {
  }
}
</script>
