<template>


  <h3>{{ $t("thankyou.h3") }}</h3>
  <p v-if="$store.state.order.method === 'paper'">{{ $t("thankyou.paper") }}</p>
  <p v-else>{{ $t("thankyou.digital") }}</p>
    <button class="btn" @click="$router.push('/')"  >{{ $t("app.backToStart") }}</button>



</template>
<script>
export default {
  name: "Digital",
  methods: {
    goPrivate() {

      this.$store.state.order.usage = "p"
      this.$router.push("/address");
    },
    goCommercial() {
      this.$store.state.order.usage = "c"
      this.$router.push("/address");
    }
  },
  mounted() {
    this.$store.state.progress = 100
  }
}

</script>
<style scoped>
h3{
  text-align: center;
}
p{
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
}
</style>
