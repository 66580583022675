<template>
  <div class="progressBar">
    <div class="progressBar__inner" :style="{width: progress + '%'}">
      <div class="progressBar__inner__text">{{ progress }}%</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "progressbar",
  props: {
    progress: {
      type: Number,
      default: 0
    }
  }
}
</script>
