<template>
  <h3>{{ $t("outputs.intro") }}</h3>
  <div class="selectBoxes asWrap outputs">
    <template v-for="(station, key) in $store.state.stations">
      <template v-if="( $store.state.order.usage === 'c' && station.id === '2' ) || $store.state.order.usage === 'p'">
      <div class="itemWrap" @click="selectStation(station)"
           :class="(station.id === $store.state.activeStation.id) ? 'active' : ''">
        <div class="box">
          <p>{{ station.c_company }}<br>
            {{ station.c_street }}<br>
            {{ station.c_zipcode }} {{ station.c_city }}<br>
          </p>
        </div>
        <div class="buttonWrap" v-if="station.id === $store.state.activeStation.id">
          <button class="btn" @click="openModal = 1" v-if="station.c_infos_official">{{ $t("outputs.openingtimes") }}</button>
          <a class="btn" target="_blank" :href="station.googleMaps">{{ $t("outputs.showonmap") }}</a>
        </div>
      </div></template>
    </template>
  </div>
  <div class="doubleButton fixedButtons">
    <button class="btn" @click="$router.push('/address')">{{ $t("app.back") }}</button>
    <template v-if="$store.state.activeStation">
      <button class="btn" @click="selectAddress">{{ $t("app.next") }}</button>
    </template>
    <template v-else>
      <button class="btn" disabled>{{ $t("app.next") }}</button>
    </template>
  </div>
  <modal v-if="openModal === 1" @click="openModal = 0" class="popup">
    <div class="container" v-html="$store.state.activeStation.c_infos_official">

    </div>
  </modal>
</template>
<script>
import {ref} from "vue";
import axios from "axios";
import environment from "@/auth/environment";
export default {
  name: "Outputs",
  data() {
    return {
    openModal: 0
  }},
  setup() {
    const isShow = ref(false)

    function showModal() {
      isShow.value = true
    }

    function closeModal() {
      isShow.value = false
    }

    return {isShow, showModal, closeModal}
  },
  methods: {
    selectAddress() {
      this.$router.push("/reviewoutput");

    },
    selectStation(station) {
      if (station) {
        this.$store.state.activeStation = station
        this.$store.state.order.activeStation = station
      } else {
        this.$store.state.activeStation = false
        this.$store.state.order.activeStation = false
      }

    }
  },
  created() {
    this.$store.state.progress = 45;
    //this.$store.dispatch('handleCoupon');
  }
}
</script>
<style scoped>
</style>
