import {createRouter, createWebHistory} from 'vue-router'
// import ProfileView from '../views/ProfileView.vue'
import Reorder from '../views/Reorder.vue'
import Usage from '../views/Usage.vue'
import Address from '../views/Address.vue'
import ThankYou from '../views/ThankYou.vue'
import Outputs from '../views/Outputs.vue'
import store from '../store/index'
import {useToast} from "vue-toastification";

const toast = useToast();
const routes = [
  {
    path: '/',
    name: 'reorder',
    component: Reorder
  },
  {
    path: '/usage',
    name: 'usage',
    component: Usage
  },
  {
    path: '/address',
    name: 'address',
    component: Address
  },
  {
    path: '/thankyou',
    name: 'thankyou',
    component: ThankYou
  },
  {
    path: "/activate",
    name: "activate",
    component: () => import("../views/Activate.vue"),
  },
  {
    path: "/activate/:token",
    name: "activate",
    component: () => import("../views/Activate.vue"),
  },
  {
    path: '/outputs',
    name: 'outputs',
    component: Outputs
  },
  {
    path: '/reviewoutput',
    name: 'reviewoutput',
    component: () => import('../views/Reviewoutput.vue')
  },
  {
    path: '/gofetch',
    name: 'gofetch',
    component: () => import('../views/Gofetch.vue')
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  store.commit("forceSWupdate");
  if (to.name === 'activated') {
    store.commit("removeActivation")
    toast('Vielen Dank für die Aktivierung!');
  }
  // if (to.name !== 'login' && to.name !== 'auth' && to.name !== 'home' && to.name !== "activated" && to.name !== "imprint" && !store.state.loggedInState) {
  //   router.push({name: 'home'});
  // }
  // if ((to.name === 'login' || to.name === 'home' || to.name === 'register' || to.name === "activated") && store.state.loggedInState) {
  //   router.push({name: 'dashboard'});
  // }
  next();
});
export default router
