const dev = {
  apiUrl: 'https://entsorgungsdaten-api-dev.sketch-manager.de/',
  version: '0.0.12'
};


// const prod = {
//   clientId: 'ak-bewerbungsmanagement',
//   OPServer: 'https://oidc.dfb.de/auth/realms/AK',
//   redirectURL: 'https://dfb-trainerbewerbung.de/auth/siginwin/main',
//   logoutUrlComplete: 'https://oidc.dfb.de/auth/realms/AK/protocol/openid-connect/logout?redirect_uri=https%3A%2F%2Fdfb-trainerbewerbung.de%2Fauth%2Fsiginwin%2Fmain',
//   scope: 'openid account_by_dfbnet_id',
//   userInfoEndpoint: '/protocol/openid-connect/userinfo',
//   extra: {prompt: 'consent', access_type: 'offline'},
//   apiUrl: 'https://api.dfb-trainerbewerbung.de/',
//   version: '1.0.4'
//
// };

let config = dev;

if (process.env.NODE_ENV === 'production') {
  config = dev;
}



export default {
  // Add common config values here
  ...config
};
