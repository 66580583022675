<template>


    <h3>{{ $t("reorder.pleaseSelect") }}</h3>
    <div class="selectBoxes">

      <div class="box" @click="goDigital">
        <h3>{{ $t("reorder.digitalOrder") }}</h3>
        <p>{{ $t("reorder.digitalOrderText") }}</p>
      </div>
      <div class="box" @click="goPaper">
        <h3>{{ $t("reorder.paperOrder") }}</h3>
        <p>{{ $t("reorder.paperOrderText") }}</p>
      </div>
    </div>
    <a class="btn" style="width: 100%;" href="https://www.entsorgungsdaten.de">{{ $t("app.back") }}</a>

</template>
<script>
export default {
  name: "Reorder",
  methods: {
    goDigital() {

      this.$store.state.order.method = "digital"
      this.$router.push("/usage");
    },
    goPaper() {
      this.$store.state.order.method = "paper"
      this.$router.push("/usage");
    }
  },
  // set progress to 0
  mounted() {
    this.$store.state.progress = 0
  }
};
</script>
